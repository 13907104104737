<div
    class="heading-container"
    *ngIf="!node.children">
    <a
        href="{{node.url}}"
        [ngClass]="classes"
        title="{{node.tooltip}}"
        class="vertical-menu-item">
        {{node.title}}
    </a>
</div>

<div
    class="heading-container"
    *ngIf="node.children">
    <a
        *ngIf="node.url != null"
        href="{{node.url}}"
        [ngClass]="classes"
        title="{{node.tooltip}}"
        (click)="headerClicked()"
        class="vertical-menu-item heading">
        <span class="node-title">{{node.title}}</span>
        <mat-icon
            class="rotating-icon"
            svgIcon="keyboard_arrow_right"></mat-icon>
    </a>

    <button
        *ngIf="node.url == null"
        type="button"
        [ngClass]="classes"
        title="{{node.tooltip}}"
        (click)="headerClicked()"
        class="vertical-menu-item heading"
        [attr.aria-pressed]="isExpanded">
        <span class="node-title">{{node.title}}</span>
        <mat-icon
            class="rotating-icon"
            svgIcon="keyboard_arrow_right"></mat-icon>
    </button>

    <div
        class="heading-children"
        [ngClass]="classes">
        <aio-nav-item
            *ngFor="let node of nodeChildren"
            [level]="level + 1"
            [isWide]="isWide"
            [isParentExpanded]="isExpanded"
            [node]="node"
            [selectedNodes]="selectedNodes"></aio-nav-item>
    </div>
</div>
